import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { sendTicketMessage } from '@/utils/embeddedUtils';

const query = gql`
   mutation IssueFrsFileRepositoryDownloadTickets($input: IssueFrsFileRepositoryTicketsInput!) {
      issueFrsFileRepositoryDownloadTickets(input: $input) {
         serviceUrl
         bucket
         bucketRegion
         accessKey
         secretAccessKey
         sessionToken
         expiration
         prefix
      }
   }
`;

export const getDownloadTicket = (client, repositoryId) =>
   client
      .mutation(query, { input: { repositoryId } })
      .toPromise()
      .then(path(['data', 'issueFrsFileRepositoryDownloadTickets']))
      .then(sendTicketMessage);

export const getDownloadTicketGateway = always(Promise.resolve(null));
