import { __, adjust, assoc, dissoc, evolve, find, isNotNil, mergeDeepLeft, path, propEq, set } from 'ramda';

import { allFilesWithRepoId } from '@/store/files/fileUtils';
import { getClientAndSignedUrl } from '@/store/files/files';

import { stopFileRepositoryPolling } from '@/utils/JobOutputUtils';
import { getJsonWithStatus, getTaskName } from '@/utils/accessors';
import { dataStates, downloadDelay, submittedFilesPath } from '@/utils/constants';
import { sendEmbeddedMessage } from '@/utils/embeddedMessage';
import { sendEmbeddedMessageForEachFile } from '@/utils/embeddedUtils';
import { debounce } from '@/utils/utils';

import { refinerTrackFileDownload } from '@/analytics/refiner';
import { getServices } from '@/services/services';

import { model } from './model';
import { extractJobFiles, inputFileStatusLens, outputFileStatusLens, transformUploadInfo } from './utils';

export const actions = (present) => {
   return {
      setJobFiles: ({ config, inputFiles, taskName, id, form }) => {
         const value = extractJobFiles({ config, inputFiles, taskName, form });
         present({ op: 'add', value, path: `${submittedFilesPath}/${id}` });
      },

      updateUploadProgress: (uploadInfo, id, index) => {
         const path = `${submittedFilesPath}/${id}/files/${index}/upload`;
         const value = transformUploadInfo(uploadInfo);

         present({ op: 'replace', path, value });
      },

      abortUpload: (id, index) => {
         present(
            evolve({
               submittedFiles: {
                  [id]: { files: adjust(index, mergeDeepLeft({ upload: { status: 'terminated' } })) },
               },
            }),
         );
      },

      pauseUpload: (id, index) => {
         present(
            evolve({
               submittedFiles: {
                  [id]: { files: adjust(index, mergeDeepLeft({ upload: { status: 'paused' } })) },
               },
            }),
         );
      },

      setUploadError: (id, index, message) => {
         present(
            evolve({
               submittedFiles: {
                  [id]: {
                     files: adjust(index, mergeDeepLeft({ upload: { status: 'error', errorMessage: message } })),
                  },
               },
            }),
         );
      },

      clearSubmittedFilesById: (id) => {
         present(evolve({ submittedFiles: dissoc(id) }));
      },

      loadJobInputFiles: (client, urn, job) => {
         present(set(inputFileStatusLens, dataStates.loading));
         return getServices()
            .getInputFileRepository(client, urn)
            .then((frsFileRepository) => {
               present({
                  fileRepository: {
                     input: frsFileRepository,
                     dataState: { input: dataStates.ready },
                  },
               });
               const repoFiles = allFilesWithRepoId(frsFileRepository, null);
               const configFile = find(propEq('.config.json', 'filename'), repoFiles);
               if (isNotNil(configFile)) {
                  return getServices()
                     .getDownloadTicket(client, configFile.repositoryId)
                     .then(getClientAndSignedUrl(configFile.filename))
                     .then(fetch)
                     .then(getJsonWithStatus)
                     .then((res) => {
                        const value = extractJobFiles({
                           config: res,
                           inputFiles: frsFileRepository.files,
                           taskName: getTaskName(job),
                           form: null,
                        });
                        present({ op: 'add', value, path: `${submittedFilesPath}/${job.resourceId}` });
                     })
                     .catch((err) => console.log(err));
               }
               const value = extractJobFiles({
                  config: null,
                  inputFiles: frsFileRepository.files,
                  taskName: getTaskName(job),
                  form: null,
               });
               value.upload = {};
               present({ op: 'add', value, path: `${submittedFilesPath}/${job.resourceId}` });
            })
            .catch(() => {
               present(set(inputFileStatusLens, dataStates.error));
            });
      },

      loadJobOutputFiles: (client, job, urn) => {
         present(set(outputFileStatusLens, dataStates.loading));
         const { promise, cancel } = getServices().getOutputFileRepository(client, urn, stopFileRepositoryPolling(job));
         promise
            .then(path(['data', 'frsFileRepository']))
            .then((frsFileRepository) => {
               present({
                  fileRepository: {
                     output: frsFileRepository,
                     dataState: { output: dataStates.ready },
                  },
               });
            })
            .catch(() => {
               present(set(outputFileStatusLens, dataStates.error));
            });

         return cancel;
      },

      previewFile: (client, file) => {
         present({ op: 'replace', value: dataStates.loading, path: '/preview/state' });
         return getServices()
            .getDownloadTicket(client, file.repositoryId)
            .then(getClientAndSignedUrl(file.filename))
            .then((url) => {
               present({ op: 'replace', value: url, path: '/preview/image' });
            });
      },

      readyPreview: () => {
         present({ op: 'replace', value: dataStates.ready, path: '/preview/state' });
      },

      closePreview: () => {
         present({ op: 'replace', value: null, path: '/preview/image' });
      },

      setSearchTerm: (term) => {
         present({ op: 'replace', value: term.trim().toLowerCase(), path: '/searchTerm' });
      },

      downloadFiles: (client, downloader) =>
         debounce((fileList) => {
            fileList.forEach(({ filename, repositoryId }) => {
               getServices()
                  .getDownloadTicket(client, repositoryId)
                  .then(getClientAndSignedUrl(filename))
                  .then(downloader)
                  .then(refinerTrackFileDownload);
            });
         }, downloadDelay),

      downloadFilesEmbedded: (client, downloadPath, repoId) => (files) => {
         const processFile = ({ filename, repositoryId }) =>
            getServices()
               .getDownloadTicket(client, repositoryId)
               .then(getClientAndSignedUrl(filename))
               .then(assoc('url', __, { filename }));

         // repositoryId, like it is used here, is only an unique id for sending embedded messages,
         // it does not matter if we always use the id of the inputRepo or outputRepo or any other id
         const createTickets = (fileInfos) => assoc('files', fileInfos, { repositoryId: repoId, downloadPath });

         return Promise.all(files.map(processFile))
            .then(createTickets)
            .then((tickets) => sendEmbeddedMessage('fileTransfer/startDownload', tickets));
      },

      cancelDownload: (downloadInfo, repositoryId) => (files) =>
         sendEmbeddedMessageForEachFile('fileTransfer/cancelDownload', files, downloadInfo, repositoryId),

      pauseDownload: (downloadInfo, repositoryId) => (files) =>
         sendEmbeddedMessageForEachFile('fileTransfer/pauseDownload', files, downloadInfo, repositoryId),

      resumeDownload: (downloadInfo, repositoryId) => (files) =>
         sendEmbeddedMessageForEachFile('fileTransfer/resumeDownload', files, downloadInfo, repositoryId),

      setDownloadInfo: (fileKey, value) => {
         present({ op: 'add', value, path: `/downloadInfo/${fileKey}` });
      },

      setOverallDownloadInfo: (repositoryId, value) => {
         present({ op: 'add', value, path: `/overallDownloadInfo/${repositoryId}` });
      },

      setDownloadPathEmbedded: (repositoryId, downloadPath) => {
         sendEmbeddedMessage('fileTransfer/setDownloadPath', { repositoryId, downloadPath });
      },

      storeDownloadPath: (repositoryId, downloadPath) => {
         present({ op: 'add', value: downloadPath, path: `/downloadPath/${repositoryId}` });
      },

      // Clear only objects with static keys in the store, not the entire store
      // e.g. this clear method DO NOT clear submittedFiles, downloadInfo, overallDownloadInfo,downloadPath
      clear: () => present(model),

      // only for testing
      test: {
         initFileStoreState: (fakeData) => {
            present({ op: 'replace', value: fakeData, path: '' });
         },
         initOutputs: (repo) => {
            present({
               fileRepository: {
                  output: repo,
                  dataState: { output: dataStates.ready },
               },
            });
         },
         clearDownloadInfo: () => {
            present({ op: 'replace', value: {}, path: '/downloadInfo' });
         },
         clearEverything: () => {
            present({ op: 'replace', value: model, path: '' });
         },
      },
   };
};
