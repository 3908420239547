import { gql } from '@urql/core';
import { always } from 'ramda';

import { makeUpdateEntitlementInput } from '@/store/settings/utils';

const mutation = gql`
   mutation UpdateUserEntitlementData($input: UpdateUserEntitlementDataInput!) {
      updateUserEntitlementData(input: $input) {
         entitlementDataId
         viewedTelemetry
         csaAcceptanceDate
         telemetryEnabled
      }
   }
`;

export const updateEntitlementData = ({ client, entitlementDataId, pepTelemetryEnabled, telemetryEnabled }) => {
   const input = makeUpdateEntitlementInput(entitlementDataId, pepTelemetryEnabled, telemetryEnabled);
   return client.mutation(mutation, { input }).toPromise();
};

export const updateEntitlementDataGateway = always(Promise.resolve(null));
