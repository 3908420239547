import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const query = gql`
   query Job($resourceId: ID!) {
      job(resourceId: $resourceId) {
         tasks {
            name
            inputFileRepository {
               id
               urn
            }
            outputFileRepository {
               id
               urn
            }
         }
         jobDefinition {
            computeType {
               name
            }
         }
      }
   }
`;

export const getDetails = (client, resourceId) =>
   client
      .query(query, { resourceId })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'job']));

export const getDetailsGateway = always(Promise.resolve(null));
