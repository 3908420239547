// @ts-nocheck
import { stream } from 'flyd';
import afterSilence from 'flyd/module/aftersilence';
import { compose, ifElse, isNil, last, map, props, unless } from 'ramda';

import { createApp } from '@/store/common/pal';

import { propIsNilOrEmpty } from '@/utils/comparators';
import {
   computeTaskUnifiedStatus,
   cursorDefault,
   dataStatePath,
   dataStates,
   lineHeight,
   linesToFetch,
} from '@/utils/constants';
import { decodeToNum } from '@/utils/formatters';

import { getServices } from '@/services/services';

import { nap } from './nap';
import { updateScrollDataWhenChanged } from './utils';

const model = {
   dataState: dataStates.loading,
   nodes: [],
   logsHeight: lineHeight,
   autoScroll: false,
   startCursor: cursorDefault,
   endCursor: cursorDefault,
   isVisible: true,
   timeoutId: null,
};

const decodeCursors = compose(map(decodeToNum), props(['startCursor', 'endCursor']));
const calcLogsHeight = (nLines) => (nLines + 1) * lineHeight;

const rawActions = (present) => {
   const unlessNodesEmpty = ifElse(propIsNilOrEmpty('nodes'), () =>
      present({ op: 'replace', value: dataStates.ready, path: dataStatePath }),
   );

   const presentAutoscroll = (client, task) =>
      ifElse(
         propIsNilOrEmpty('nodes'),
         () => present({ dataState: dataStates.ready }, { client, task }),
         ({ nodes, pageInfo }) => {
            const [startCursor, totalLines] = decodeCursors(pageInfo);
            present(
               {
                  dataState: dataStates.ready,
                  nodes: nodes,
                  logsHeight: calcLogsHeight(totalLines),
                  autoScroll: true,
                  startCursor,
                  endCursor: totalLines,
               },
               { client, task },
            );
         },
      );

   const presentStandard = unlessNodesEmpty(({ nodes, pageInfo }) => {
      const [startCursor, endCursor] = decodeCursors(pageInfo);
      present({ dataState: dataStates.ready, nodes, autoScroll: false, startCursor, endCursor });
   });

   const performAutoscrollFetch = (client, task) => {
      present({ dataState: dataStates.loading });
      getServices().getLogs(client, task.id, { last: 100 }).then(presentAutoscroll(client, task));
   };

   const performUserScrollFetch = (client, task, scrollData) => {
      present({ dataState: dataStates.loading });
      getServices()
         .getLogs(client, task.id, { first: linesToFetch, after: btoa(scrollData.startLine - 1) })
         .then(presentStandard);
   };

   // This stream will contain every scroll event
   const scrollEvents = stream();
   let timeoutId;

   function updateDataOnScroll({ client, task, scrollData }) {
      if (scrollData.shouldAutoScroll) {
         // If it was already autoscrolling, then we need to trigger the next update with a delay.
         // If it was not already autoscrolling, it means the user has just scrolled to the bottom, so
         // we should not delay
         const delay = scrollData.isAutoScrolling ? 5000 : 0;
         timeoutId = setTimeout(() => performAutoscrollFetch(client, task), delay);
      } else {
         clearTimeout(timeoutId);
         performUserScrollFetch(client, task, scrollData);
      }
   }

   // Throttle the scroll event stream so we only trigger updates every 250ms
   // when user is scrolling
   afterSilence(250, scrollEvents).map(compose(updateDataOnScroll, last));

   return {
      initialize: (client, task) => {
         // set to loading to prevent other fetching attempts
         present({ op: 'replace', value: dataStates.loading, path: dataStatePath });

         // Initially fetch the last lines to be able to get the total height of the logs
         return getServices()
            .getLogs(client, task.id, { last: 100 })
            .then(
               unless(isNil, ({ nodes, pageInfo }) => {
                  const totalLines = decodeToNum(pageInfo.endCursor);
                  // If the job is executing, we stick to the bottom of the logs
                  if (task.unifiedStatus === computeTaskUnifiedStatus.Executing) {
                     presentAutoscroll(client, task)({ nodes, pageInfo });
                  }
                  // If the job has completed we want to display the logs from the start,
                  // necessitating another fetch
                  else {
                     return getServices()
                        .getLogs(client, task.id, { first: 100, after: cursorDefault - 1 })
                        .then(
                           unless(isNil, ({ nodes, pageInfo }) => {
                              const [startCursor, endCursor] = decodeCursors(pageInfo);
                              present({
                                 dataState: dataStates.ready,
                                 nodes,
                                 logsHeight: calcLogsHeight(totalLines),
                                 autoScroll: false,
                                 startCursor,
                                 endCursor,
                              });
                           }),
                        );
                  }
               }),
            );
      },

      reset: () => {
         clearTimeout(timeoutId);
         timeoutId = null;
         present(model);
      },

      /**
       * @brief This action should be called when the user scrolls the logs to trigger
       * a new fetch based on the current scroll position
       * @param {Object} client API (urql) client which provides a `query` function
       * @param {Object} task The cloud HPC task object which provides the current status
       * @param {boolean} isAutoScrolling The current value of `autoScroll` from the state
       * @param {Object} event the browser scroll event which is used to calculate the scroll position in lines
       */
      updateScroll: (client, task, isAutoScrolling, event, scrollContainerHeight) => {
         const startLine = Math.floor(event.target.scrollTop / lineHeight);
         const endLine = Math.floor((event.target.scrollTop + scrollContainerHeight) / lineHeight);
         // Checks if it is scrolled to the bottom
         const shouldAutoScroll = event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 2;

         updateScrollDataWhenChanged(scrollEvents)({
            client,
            task,
            scrollData: { startLine, endLine, shouldAutoScroll, isAutoScrolling },
         });
      },

      fetchLastLines: (client, task) => {
         present({ op: 'replace', value: dataStates.loading, path: dataStatePath });
         getServices().getLogs(client, task.id, { last: 100 }).then(presentAutoscroll(client, task));
      },

      toggleTabVisibility: (visibility) => {
         present({ op: 'replace', value: visibility, path: '/isVisible' });
      },
   };
};

export const { actions, state } = createApp(rawActions, model, nap);
