import { gql } from '@urql/core';
import { always } from 'ramda';

const mutation = gql`
   mutation deleteJob($resourceId: ID!) {
      deleteJob(input: { resourceId: $resourceId }) {
         _
      }
   }
`;

export const deleteJobs = (client, jobResourceIdList) => {
   return Promise.all(
      jobResourceIdList.map((jobResourceId) =>
         client
            .mutation(
               mutation,
               { resourceId: jobResourceId },
               {
                  additionalTypenames: ['Workspace'],
               },
            )
            .toPromise(),
      ),
   );
};

export const deleteJobsGateway = always(Promise.resolve(null));
