import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { fetchPolicies } from '@/utils/constants';

const query = gql`
   query GetWorkspace {
      workspace {
         id
         jobs: multiversion_jobs {
            id
            resourceId
            name
            status
            submissionTime
            tasks {
               id
               name
               unifiedStatus
            }
            debitReport {
               amount
               runTime
            }
            jobDefinition {
               applications {
                  version
                  precision
               }
               computeType {
                  name
               }
               jobType
            }
         }
      }
   }
`;

export const getJobs = (client) =>
   client
      .query(query, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'workspace']));

export const getJobsGateway = always(Promise.resolve(null));
