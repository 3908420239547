import { gql } from '@urql/core';
import { always } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

const mutation = gql`
   mutation TerminateJob($input: IdInput!) {
      terminateJob(input: $input) {
         job {
            resourceId
            status
            tasks {
               resourceId
               name
               status
               unifiedStatus
               application
            }
            jobDefinition {
               jobType
               applications {
                  version
                  precision
               }
            }
         }
      }
   }
`;

export const terminateJob = (client, jobResourceId) => {
   return client
      .mutation(mutation, { input: { resourceId: jobResourceId } })
      .toPromise()
      .then(throwWhenError);
};

export const terminateJobGateway = always(Promise.resolve(null));
