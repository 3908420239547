import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { fetchPolicies } from '@/utils/constants';

const query = gql`
   query GetSystemJobTemplates {
      workspace {
         systemJobTemplateCatalog {
            systemJobTemplates {
               jobType
               applicationsSupported {
                  type
                  version
                  precision
                  typeHint
               }
               computeTypes {
                  name
                  usableCoreCount
                  hourlyCreditRate
                  typeHint
               }
            }
            displayMappings {
               key
               value
            }
         }
      }
   }
`;

export const getSystemJobTemplates = (client) =>
   client
      .query(query, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'workspace', 'systemJobTemplateCatalog']));

export const getSystemJobTemplatesGateway = always(Promise.resolve(null));
