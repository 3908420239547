import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { fetchPolicies } from '@/utils/constants';

const query = gql`
   query GetFeatureFlags {
      userFeatureFlags {
         name
         value
      }
   }
`;

export const getFeatureFlags = (client) =>
   client
      .query(query, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'userFeatureFlags']));

export const getFeatureFlagsGateway = always(Promise.resolve(null));
