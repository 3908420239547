import { always, path } from 'ramda';
import { gql } from 'urql';

import { throwWhenError } from '@/store/common/utils';

import { fetchPolicies } from '@/utils/constants';

const query = gql`
   query UserEntitlementData {
      userEntitlementData {
         id
         csaAcceptanceDate
         viewedTelemetry
         telemetryEnabled
         pepTelemetryEnabled
      }
   }
`;

export const getEntitlementData = (client) =>
   client
      .query(
         query,
         {},
         {
            requestPolicy: fetchPolicies.networkOnly,
         },
      )
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'userEntitlementData']));

export const getEntitlementDataGateway = always(Promise.resolve(null));
