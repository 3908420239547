// @ts-nocheck
import { isNotNil } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { isNotNilOrEmpty } from '@/utils/comparators';
import { dataStates } from '@/utils/constants';

import { getServices } from '@/services/services';

import { model } from './model';
import { getSettings, getUpdatedEntitlement } from './utils';

const dataStatePath = '/userEntitlementData/dataState';
const pepTelemetryEnabledPath = '/telemetry/pepTelemetryEnabled';
const telemetryEnabledPath = '/telemetry/telemetryEnabled';
const viewedTelemetryPath = '/telemetry/viewedTelemetry';

export const actions = (present) => {
   return {
      initFromStorage: (ls) => {
         const settings = getSettings(ls);
         if (isNotNil(settings)) {
            present({ op: 'replace', value: settings.fullWidthTable, path: '/fullWidthTable' });
         }
      },

      setFullWidthTableSetting: (ls, value) => {
         const settings = getSettings(ls) ?? {};

         settings.fullWidthTable = value;
         ls.setItem('userSettings', JSON.stringify(settings));
         present({ op: 'replace', value, path: '/fullWidthTable' });
      },

      getEntitlement: (client) => {
         present({ op: 'replace', value: dataStates.loading, path: dataStatePath });
         return getServices()
            .getEntitlementData(client)
            .then(throwWhenError)
            .then(({ id, csaAcceptanceDate, pepTelemetryEnabled, telemetryEnabled, viewedTelemetry }) => {
               const isGranted = viewedTelemetry && isNotNilOrEmpty(csaAcceptanceDate);

               present([
                  { op: 'replace', value: id, path: '/userEntitlementData/id' },
                  { op: 'replace', value: isGranted, path: '/granted' },
                  { op: 'replace', value: pepTelemetryEnabled, path: pepTelemetryEnabledPath },
                  { op: 'replace', value: telemetryEnabled, path: telemetryEnabledPath },
                  { op: 'replace', value: viewedTelemetry, path: viewedTelemetryPath },
                  { op: 'replace', value: dataStates.ready, path: dataStatePath },
               ]);
            })
            .catch(() => {
               present([
                  { op: 'replace', value: true, path: '/granted' },
                  { op: 'replace', value: false, path: pepTelemetryEnabledPath },
                  { op: 'replace', value: false, path: telemetryEnabledPath },
                  { op: 'replace', value: dataStates.error, path: dataStatePath },
               ]);
            });
      },

      updateEntitlement: (client, id) => {
         return getServices()
            .updateEntitlementData({ client, entitlementDataId: id })
            .then(throwWhenError)
            .then((res) => {
               const { viewedTelemetry, csaAcceptanceDate } = getUpdatedEntitlement(res);
               const isGranted = viewedTelemetry && isNotNilOrEmpty(csaAcceptanceDate);

               present([
                  { op: 'replace', value: isGranted, path: '/granted' },
                  { op: 'replace', value: viewedTelemetry, path: viewedTelemetryPath },
               ]);
            })
            .catch(() => {
               present([
                  { op: 'replace', value: false, path: '/granted' },
                  { op: 'replace', value: dataStates.error, path: dataStatePath },
               ]);
            });
      },

      updateProductExcellenceProgram: (client, id, pepTelemetryEnabled) => {
         const San = window.San;

         return getServices()
            .updateEntitlementData({ client, entitlementDataId: id, pepTelemetryEnabled })
            .then(throwWhenError)
            .then((res) => {
               const { viewedTelemetry } = getUpdatedEntitlement(res);

               if (pepTelemetryEnabled) {
                  San?.enableOptionalData();
               } else {
                  San?.disableOptionalData();
               }

               present([
                  { op: 'replace', value: pepTelemetryEnabled, path: pepTelemetryEnabledPath },
                  { op: 'replace', value: viewedTelemetry, path: viewedTelemetryPath },
               ]);
            })
            .catch(() => {
               present({ op: 'replace', value: false, path: pepTelemetryEnabledPath });
            });
      },

      updateDigitalProductExperience: (client, id, telemetryEnabled) => {
         const San = window.San;

         return getServices()
            .updateEntitlementData({ client, entitlementDataId: id, telemetryEnabled })
            .then(throwWhenError)
            .then(() => {
               if (telemetryEnabled) {
                  San?.enableNecessaryData();
               } else {
                  San?.disableNecessaryData();
               }

               present({ op: 'replace', value: telemetryEnabled, path: telemetryEnabledPath });
            })
            .catch(() => {
               present({ op: 'replace', value: false, path: telemetryEnabledPath });
            });
      },

      setWMCBranch: (searchParams) => {
         const wmcbranch = new URLSearchParams(searchParams).get('wmcbranch');

         //build WMC URL
         if (wmcbranch && import.meta.env.DEV) {
            const url = import.meta.env.VITE_WMC_URL.toString().replace('wmc', `${wmcbranch}/index.html#/monitor`);

            console.log('setWMCBranch URL ' + url);
            present({ op: 'replace', value: url, path: '/wmcUrl' });
         }
      },

      clear: () => present({ op: 'replace', value: model, path: '' }),
   };
};
