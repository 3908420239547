import { gql } from '@urql/core';
import { always, path } from 'ramda';

import { throwWhenError } from '@/store/common/utils';

import { fetchPolicies } from '@/utils/constants';

const query = gql`
   query GetUserBalance {
      creditData {
         user {
            balance
            effectiveBalance
            consumptionRate
         }
         account {
            balance
         }
      }
   }
`;

export const getCredits = (client) =>
   client
      .query(query, {}, { requestPolicy: fetchPolicies.networkOnly })
      .toPromise()
      .then(throwWhenError)
      .then(path(['data', 'creditData']));

export const getCreditsGateway = always(Promise.resolve(null));
